import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button, Col, Row } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

const ExEPCPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page play-on-pc-page pcp-gfl '}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_characters.jpg"
            alt="PC"
          />
        </div>
        <div className="page-details">
          <h1>
            Play and reroll in Girls' Frontline 2: Exilium on your PC with
            Bluestacks.
          </h1>
        </div>
      </div>
      <div className="page-content">
        <div className="banner-gfl">
          <div className="cta">
            <h4>Play and reroll in Girls' Frontline 2: Exilium on PC!</h4>
            <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
              <Button variant="primary">Reroll now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Girls' Frontline 2: Exilium is an upcoming turn-based tactics gacha
          game developed by Sunborn. The gameplay in GFL 2: Exilium is very
          unique when it comes to gacha games as here you control the team and
          traverse a grid-based map where you have to take into account the
          tactical implications of elevation, hard cover and line of sight. If
          you ever played the game XCOM, then GFL2 is basically that
          gameplay-wise.
        </p>
        <p>
          While the game will have an official PC Client available at launch,
          the Guest Account system isn't available there and if you plan to
          reroll, you will have to use an alternative - an emulator.
        </p>
        <h6>What is BlueStacks?</h6>
        <p>
          BlueStacks app player is the best platform to play this Android game
          on your PC or Mac for an immersive gaming experience. BlueStacks is an
          Android emulator available on Windows and macOS. Instead of playing
          your favorite games on your phone, you can do it straight from your
          PC!
        </p>
        <SectionHeader title="Benefits of using BlueStacks" />
        <ul>
          <li>
            It's not possible to reroll in the PC client. Rerolling allows you
            to get an optimal start, especially as a F2P player.
          </li>
          <li>
            BlueStacks supports multi-instances, so if you're playing multiple
            accounts or want to reroll in a more optimal way, BlueStacks easily
            allows you to do it,
          </li>
          <li>
            BlueStacks allows you to use Macros to optimize your experience -
            you can save a lot of time by creating macros that do the boring
            stuff for you!
          </li>
        </ul>
        <SectionHeader title="Multi-instance" />
        <p>
          If you want to save time while rerolling for your favorite characters,
          then the multi-instance feature available in BlueStacks is perfect for
          you! But it also comes in handy when you play multiple games and want
          to do it at the same time!
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="N2A5WtX2AVA" />
          </Col>
        </Row>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>OS: Microsoft Windows 10 and above,</li>
          <li>
            Processor: Intel or AMD Multi-Core Processor with Single Thread
            benchmark score &gt; 1000
          </li>
          <li>
            Graphics: Intel/Nvidia/ATI, Onboard or Discrete GPU with benchmark
            score &gt;= 750
          </li>
          <li>
            Please ensure{' '}
            <a
              href="https://support.bluestacks.com/hc/en-us/articles/4409279876621"
              target="_blank"
              rel="noreferrer"
              className="social-media-link"
            >
              Virtualization is enabled
            </a>{' '}
            on your PC/Laptop.
          </li>
          <li>RAM: 8GB or higher</li>
          <li>Storage: SSD (or Fusion/Hybrid Drives)</li>
        </ul>
        <p>
          To find the benchmark score of your processor (CPU) and graphics card
          (GPU), read this{' '}
          <a
            href="https://support.bluestacks.com/hc/en-us/articles/360061491412"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            article
          </a>{' '}
          .
        </p>
        <SectionHeader title="Play Girls' Frontline 2: Exilium and support Prydwen" />
        <p>
          If you download BlueStacks using our promotional link and play Girls'
          Frontline 2: Exilium (or other games) on it, you will support the
          website. Thank you!
        </p>
        <div className="banner-gfl">
          <div className="cta">
            <h4>Play and reroll in Girls' Frontline 2: Exilium on PC!</h4>
            <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
              <Button variant="primary">Reroll now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ExEPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Play and reroll in Girls' Frontline 2: Exilium on your PC."
    game="exilium"
  />
);
